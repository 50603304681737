import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ImagesService } from 'src/app/images.service';

@Component({
    selector: 'app-galerie-photos',
    templateUrl: './galerie-photos.component.html',
    styleUrls: ['./galerie-photos.component.scss'],
    standalone: false
})
export class GaleriePhotosComponent implements OnInit {
  COLUMNS_NUMBER = 3;

  albums: string[];

  constructor(private titleService: Title, private imagesService: ImagesService) {
    this.titleService.setTitle('Galerie photos');

    this.imagesService.albumFiles$.subscribe((albumFiles) => {
      this.albums = albumFiles.map((albumFile) => albumFile.album);
    });
  }

  ngOnInit() {}
}
